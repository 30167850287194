import jQuery from "jquery";

export default {
  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  },
  formatByteSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  },
  formatPhoneNumber(str) {
    return str
      .replaceAll("(", "")
      .replaceAll(")", "")
      .replaceAll("-", "")
      .replaceAll(" ", "");
  },
  properCase(value) {
    if (!value) return;

    const words = value.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
  },
  addProcessingMask(element_id) {
    const el = jQuery(element_id);
    el.prop("disabled", true);

    // disable the back button too
    el.next().prop("disabled", true).addClass("processing");

    el.addClass("processing").html("PROCESSING...");
  },
  addProcessingMaskSubmit(element_id) {
    const el = jQuery(element_id);
    el.addClass("processing").html("PROCESSING...").prop("disabled", true);
  },
  removeProcessingMask(element_id) {
    const el = jQuery(element_id);

    el.next().prop("disabled", false).removeClass("processing");
    el.removeClass("processing").html("CONTINUE").prop("disabled", false);
  },
  removeProcessingMaskSubmit(element_id) {
    const el = jQuery(element_id);
    el.removeClass("processing")
      .html("SUBMIT APPLICATION")
      .prop("disabled", false);
  },
  formatMoneyFromString(mon) {
    if (mon == "") {
      return 0;
    }
    let str = String(mon)
      .replace("$", "")
      .replaceAll(" ", "")
      .replaceAll(",", "");
    let fl = parseFloat(str);
    let ret = +fl;
    return ret;
  },
  formatStringFromMoney(mon) {
    if (!mon || mon == 0) return "$0.00";
    mon = String(mon);
    return `$${this.seperateByThousandths(mon, ",")}`;
  },
  seperateByThousandths(str, delim) {
    // "1000" -> "1,000"
    return str.replace(/\B(?=(\d{3})+(?!\d))/g, `${delim}`);
  },
  formatPercentage(num) {
    return `${num}%`;
  },
  formatSSNForPayload(ssn) {
    return ssn.replaceAll("-", "");
  },
};
